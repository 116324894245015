import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Box, Heading } from "grommet"
import styled from "styled-components"
import Helmet from "react-helmet"

const FrameContainer = styled.div`
  overflow: auto;
`

const ShopPage = () => {
  React.useEffect(() => {})
  return (
    <Layout>
      <Helmet>
        <script
          id="redbubble"
          type="text/javascript"
          src="https://www.redbubble.com/assets/external_portfolio.js"
        ></script>
        <script type="text/javascript">
          {`setTimeout(() =>
          new RBExternalPortfolio('www.redbubble.com', 'philipxzhang', 5,
          5).renderIframe(), 1500)`}
        </script>
      </Helmet>
      <Seo title="shop" />
      <Box flex="grow">
        <Heading>my shop</Heading>
        <Heading level={3}>photo prints, stickers, merch, and more...</Heading>
        <FrameContainer>
          <div id="rb-xzfcxvzx" />
        </FrameContainer>
      </Box>
    </Layout>
  )
}

export default ShopPage
